import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (min-width: 1200px) {
    padding-top: 25px;
    padding-bottom: 50px;
    margin: 0 auto;
    margin-bottom: -450px;
    background-color: whitesmoke;
    position: relative;
    z-index: 11;
  }
`;

const statute = () => (
  <Layout>
    <Wrapper>
      <div className="txt">
        <div className="txt-container" style={{ marginBottom: "50px" }}>
          <h1>Polityka prywatności</h1>
          <h2>Informacje zostały przygotowane z uwzględnieniem RODO</h2>
        </div>
        <h2>1. ADMINISTRATOR DANYCH OSOBOWYCH</h2>
        <p>Jerzy Bielecki, prowadzący działalność gospodarczą pod firmą</p>
        <p>JB Multimedia Jerzy Bielecki</p>
        <p>ul. Brzeska 107a</p>
        <p>21-500 Biała Podlaska</p>
        <p>NIP: 5371234290</p>
        <p>REGON: 030156724</p>
        <h2>2. DANE OSOBOWE I PRYWATNOŚĆ</h2>
        <p>
          Jeśli zamierzasz założyć konto Użytkownika oraz korzystać z naszych
          usług, zostaniesz poproszony o podanie nam Twoich danych osobowych.
        </p>
        <p>
          Twoje dane przetwarzane są przez nas we wskazanych poniżej celach,
          związanych z funkcjonowaniem Strony i świadczeniem usług w nim
          oferowanych („Usługi”). Usługi te mogą polegać m.in. na prowadzeniu
          Twojego konta.
        </p>
        <p>Cel przetwarzania:</p>
        <ol type="a">
          <li>
            świadczenie usług oferowanych w Stronie, np. usługi prowadzenia
            konta,
          </li>
          <li>realizacja Twoich zamówień,</li>
          <li>przesyłanie newslettera,</li>
          <li>
            marketing bezpośredni, mogący polegać na wyświetlaniu
            spersonalizowanych produktów (możemy wyświetlać Ci produkty, którymi
            możesz być zainteresowany w oparciu o działania innych użytkowników,
            którzy wyświetlali ten sam produkt).
          </li>
        </ol>
        <p>Podstawa przetwarzania danych:</p>
        <ol type="a">
          <li>
            umowa o świadczenie usług lub działania podejmowane na Twoje
            żądanie, zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO),
          </li>
          <li>
            umowa sprzedaży lub działania podejmowane na Twoje żądanie,
            zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO),
          </li>
          <li>
            ciążący na nas obowiązek prawny, np. związany z rachunkowością (art.
            6 ust. 1 lit. c RODO),
          </li>
          <li>
            Twoja zgoda wyrażona w Stronie – jeśli zdecydujesz się na jej
            wyrażenie (art. 6 ust. 1 lit. a RODO),
          </li>
          <li>
            nasz prawnie uzasadniony interes, polegający na przetwarzaniu w celu
            ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust.
            1 lit. f RODO),
          </li>
          <li>
            nasz prawnie uzasadniony interes, polegający na prowadzeniu
            marketingu bezpośredniego polegającego na wyświetlaniu dopasowanych
            do Ciebie reklam i produktów (art. 6 ust. 1 lit. f RODO),
          </li>
          <li>
            nasz prawnie uzasadniony interes, polegający na zbieraniu opinii o
            zakupach w naszym Stronie na portalach z opiniami i porównywarkach
            cenowych.
          </li>
        </ol>
        <p>Podanie danych:</p>
        <p>
          dobrowolne, ale w niektórych przypadkach może być konieczne do
          zawarcia umowy
        </p>
        <p>Skutek niepodania danych:</p>
        <p>W zależności od celu, w jakim dane są podawane:</p>
        <ol type="a">
          <li>brak możliwości zarejestrowania się w Stronie,</li>
          <li>brak możliwości korzystania z usług Strony,</li>
          <li>brak możliwości dokonania zakupów w Stronie,</li>
          <li>
            brak możliwości otrzymywania informacji o promocjach czy ofertach
            specjalnych oferowanych w Stronie,
          </li>
          <li>
            brak możliwości otrzymywania sugestii produktów, którymi możesz być
            zainteresowany.
          </li>
        </ol>
        <p>Możliwość cofnięcia zgody:</p>
        <p>w każdej chwili</p>
        <p>
          Przetwarzanie danych do momentu cofnięcia przez Ciebie zgody pozostaje
          zgodne z prawem.
        </p>
        <h2>3. ZAMIESZCZANIE TREŚCI W SERWISIE</h2>
        <p>
          Jeśli zdecydujesz się zostawić komentarz w Stronie – jego treść oraz
          podana nazwa autora mogą zostać opublikowane w Stronie. To samo
          dotyczy innych treści, które publikujesz w Stronie.
        </p>
        <h2>4. PROFILOWANIE</h2>
        <p>
          W ramach Strony możemy automatycznie dopasowywać pewne treści do
          Twoich potrzeb, tj. dokonywać profilowania, wykorzystując do tego
          Twoje dane osobowe. Profilowanie to polega przede wszystkim na
          automatycznej ocenie, jakimi produktami możesz być zainteresowany na
          podstawie Twoich dotychczasowych działań, i na wyświetlaniu
          profilowanych w ten sposób sugestii innych produktów dostępnych w
          naszym Stronie.
        </p>
        <p>
          Profilowanie, którego dokonujemy, nie skutkuje podejmowaniem decyzji
          wywołujących wobec Ciebie skutki prawne lub wpływających na Ciebie w
          podobnie istotny sposób.
        </p>
        <h2>5. OKRES PRZETWARZANIA</h2>
        <p>
          Twoje dane będziemy przetwarzać tylko przez okres, w którym będziemy
          mieć ku temu podstawę prawną, a więc do momentu, w którym:
        </p>
        <ol type="a">
          <li>
            przestanie ciążyć na nas obowiązek prawny, zobowiązujący nas do
            przetwarzania Twoich danych (zwłaszcza związany z rachunkowością)
            lub
          </li>
          <li>
            ustanie możliwość ustalania, dochodzenia lub obrony roszczeń
            związanych z umową zawartą przez każdą ze stron lub
          </li>
          <li>
            cofniesz zgodę na przetwarzanie danych, jeśli to ona była jego
            podstawą lub
          </li>
          <li>uwzględnimy Twój sprzeciw wobec przetwarzania danych</li>
        </ol>
        <p>
          – w zależności od tego, co ma zastosowanie w danym przypadku i co
          nastąpi najpóźniej.
        </p>
        <h2>6. BEZPIECZEŃSTWO DANYCH</h2>
        <p>
          Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i
          techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy
          szyfrowanie połączenia za pomocą certyfikatu SSL.
        </p>
        <h2>7. TWOJE UPRAWNIENIA</h2>
        <p>Ponadto przysługuje Ci prawo żądania:</p>
        <ol type="a">
          <li>dostępu do Twoich danych osobowych,</li>
          <li>ich sprostowania,</li>
          <li>usunięcia,</li>
          <li>ograniczenia przetwarzania,</li>
          <li>żądania przeniesienia danych do innego administratora.</li>
          <li>
            {" "}
            A także prawo wniesienia w dowolnym momencie sprzeciwu wobec
            przetwarzania Twoich danych z przyczyn związanych z Twoją szczególną
            sytuacją – wobec przetwarzania dotyczących Ciebie danych osobowych,
            opartego na art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych
            interesach realizowanych przez administratora), w tym profilowania
            na podstawie tych przepisów, jeżeli dane osobowe są przetwarzane na
            potrzeby marketingu bezpośredniego, w tym profilowania, w zakresie,
            w jakim przetwarzanie jest związane z takim marketingiem
            bezpośrednim. Skontaktuj się z nami, jeśli chcesz skorzystać ze
            swoich praw. Jeśli uznasz, że Twoje dane są przetwarzane niezgodnie
            z prawem, możesz złożyć skargę do organu nadzorczego.
          </li>
        </ol>
        <br />
        <h2>8. CIASTECZKA</h2>
        <p>Nasza Strona nie korzysta z tzw. plików cookies.</p>
        {/* <p>
          Nasza Strona, jak większość witryn internetowych, korzysta z tzw.
          plików cookies (ciasteczek). Pliki te:
        </p>
        <p>
          Pliki te są zapisywane w pamięci Twojego urządzenia (komputera,
          telefonu, itd.); umożliwiają Ci, m.in., korzystanie ze wszystkich
          funkcji Strony; nie powodują zmian w ustawieniach Twojego urządzenia.
          Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej chwili
          możesz:
        </p>
        <ol type="a">
          <li>usunąć pliki cookies,</li>
          <li>blokować wykorzystanie plików cookies w przyszłości.</li>
        </ol>
        <p>Na tej Stronie ciasteczka wykorzystywane są w celu:</p>
        <ol type="a">
          <li>zapamiętywania informacji o Twojej sesji,</li>
          <li>statystycznych,</li>
          <li>marketingowych,</li>
          <li>udostępniania funkcji Strony.</li>
        </ol>
        <p>
          Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak wyłączyć
          ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy
          Twojej przeglądarki. Z informacjami na ten temat możesz zapoznać się
          wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie wskazówki
          znajdziesz na następujących podstronach, w zależności od przeglądarki,
          której używasz:
        </p>
        <ol type="a">
          <li>Firefox</li>
          <li>Chrome</li>
          <li>Internet Explorer / Microsoft Edge</li>
        </ol>
        <p>Więcej informacji o ciasteczkach znajdziesz w Wikipedii.</p> */}

        <h2>9. USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</h2>
        <p>
          W związku z naszyą Stroną korzystamy z usług podmiotów zewnętrznych,
          którym mogą być przekazywane Twoje dane (wyłącznie w celach wskazanych
          w niniejszej polityce).
        </p>
        <p>Poniżej znajduje się lista możliwych odbiorców Twoich danych:</p>
        <ol type="a">
          <li>
            dostawca oprogramowania potrzebnego do prowadzenia Strony
            internetowej
          </li>
          <li>podmioty realizujące dostawę towarów</li>
          <li>hostingodawca</li>
          <li>
            podmioty zapewniające usługi marketingowe (w tym portale
            społecznościowe)
          </li>
          <li>podmiot zapewniający nam wsparcie techniczne</li>
          <li>
            odpowiednie organy publiczne w zakresie, w jakim Administrator jest
            zobowiązany do udostępnienia im danych
          </li>
        </ol>
        <h2>
          10. PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW SPOZA UNII EUROPEJSKIEJ
        </h2>
        <p>
          W związku z powyższym, Twoje dane osobowe mogą być przetwarzane
          również przez podmioty spoza Unii Europejskiej. Właściwy poziom
          ochrony Twoich danych, w tym poprzez stosowanie odpowiednich
          zabezpieczeń, zapewnia uczestnictwo tych podmiotów w Tarczy
          Prywatności UE-USA, ustanowionej decyzją wykonawczą Komisji
          Europejskiej jako zbiór zasad gwarantujących odpowiednią ochronę
          Twojej prywatności.
        </p>
        <h2>11. KONTAKT Z ADMINISTRATOREM</h2>
        <p>
          Chcesz skorzystać ze swoich uprawnień dotyczących danych osobowych?
        </p>
        <p>
          A może po prostu chcesz zapytać o coś związanego z naszą Polityką
          Prywatności?
        </p>
        <p>Napisz na adres e-mail: kontakt@jbmultimedia.pl</p>
        <p>****</p>
        <h3>
          <b>Biała Podlaska 01.11.2023 r.</b>{" "}
        </h3>
        <p>Zapoznaj się z naszym:</p>
        <Link to="/regulamin">
          <h2>Regulaminem</h2>
        </Link>
      </div>
    </Wrapper>
  </Layout>
);

export const Head = () => <Seo title="Polityka prywatności" />;

export default statute;
